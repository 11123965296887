import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast, IndividualConfig } from 'ngx-toastr';
import { ErrorFormatter } from './error-formatter.service';
import { ErrorResponse } from './airbrush/base/error-response.model';

export interface NotificationConfig {
  description: string;
  title: string;
  config?: IndividualConfig | Partial<IndividualConfig>;
  type?: string;
}

export type ToastrMethod = 'show' | 'success' | 'error' | 'info' | 'warning';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastrService: ToastrService,
    private errorFormatter: ErrorFormatter
  ) {}

  success(options: NotificationConfig): ActiveToast<any> {
    return this.callToastr(
      'success',
      options.description,
      options.title,
      options.config as IndividualConfig
    );
  }

  error(options: NotificationConfig): ActiveToast<any> {
    return this.callToastr(
      'error',
      options.description,
      options.title,
      options.config as IndividualConfig
    );
  }

  formattedErrorResponse(error: ErrorResponse | HttpErrorResponse | Partial<ErrorResponse & HttpErrorResponse>) {
    const formattedError = this.errorFormatter.format(error);
    this.error(formattedError);
  }

  warning(options: NotificationConfig): ActiveToast<any> {
    return this.callToastr(
      'warning',
      options.description,
      options.title,
      options.config as IndividualConfig
    );
  }

  info(options: NotificationConfig): ActiveToast<any> {
    return this.callToastr(
      'info',
      options.description,
      options.title,
      options.config as IndividualConfig
    );
  }

  show(options: NotificationConfig): ActiveToast<any> {
    return this.callToastr(
      'show',
      options.description,
      options.title,
      options.config as IndividualConfig,
      options.type
    );
  }

  remove(toastId: number): boolean {
    return this.toastrService.remove(toastId);
  }

  private callToastr(
    identifier: ToastrMethod,
    description: string,
    title: string,
    config?: IndividualConfig,
    type?: string
  ): ActiveToast<any> {

    config = config || {} as IndividualConfig;
    config.positionClass = config.positionClass || 'toast-bottom-center';

    return this.toastrService[identifier](
      description,
      title,
      config,
      type
    );
  }
}
