import { Injectable } from '@angular/core';
import { ErrorResponse } from './airbrush/base/error-response.model';

export interface FormattedError {
  readonly description: string;
  readonly fullDescription: string;
  readonly title: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorFormatter {
  format(error: Partial<ErrorResponse>): FormattedError {
    const fullDescription = error?.errors?.[0]?.detail || error?.message || error?.errors?.[0]?.code || '';
    const description = this.formatShortDescription(fullDescription);
    const title = error?.errors?.[0]?.title ?? $localize `:@@common.unknown_server_error.title:Unknown server error`;
    return { description, fullDescription, title };
  }

  private formatShortDescription(message: string): string {
    if (!message) {
      return message;
    }
    const MAX_LENGTH = 128;
    const long = message.length >= MAX_LENGTH;
    return long ? `${message.slice(0, MAX_LENGTH)}...` : message;
  }
}
